@font-face {
  font-display: swap;
  font-family: 'Open Sans';
  font-weight: 700;
  src: url('/design/source/fonts/OpenSans-Bold.ttf');
}

@font-face {
  font-display: swap;
  font-family: 'Open Sans';
  font-weight: 400;
  src: url('/design/source/fonts/OpenSans-Regular.ttf');
}
