@import '../default/variables';

$paid-article-logo: '/design/source/images/mz+_blau.svg';
$paywall-plus-logo: '/design/source/images/logo-mz-plus.svg';
$paywall-logo-flyin: '/design/source/images/logo-mz.png';
$paywall-logo: '/design/source/images/logo-mz-metered.svg';

// Color Variables
$color-primary: #009ee0;
$text-color: #323232;
$light-gray: #f0f0f0;
$gray: #ccc;
$dark-gray: #a6a6a6;
$special-color-1: #db0031;
$special-color-2: #daeeff;

// Typography Variables
$font-primary: 'Open Sans', sans-serif;
